import { UniversalHero } from 'components/UniversalHero/UniversalHero';
import * as React from 'react';
import Layout from 'layout/index';
import { PartnersSection } from 'sections/PartnersSection/PartnersSection';
import { getDisabledPages } from 'utils/utils';

interface PartnersPageProps {
    location: {
        pathname: string;
    };
    pageContext: any;
}

export default ({ pageContext, location }: PartnersPageProps) => {
    const partnersData =
        pageContext?.data?.wpInvestment?.investment?.partnersLogos;

    const pageTitle = `${pageContext?.title} - ${partnersData?.title}`;

    return (
        <Layout
            displayPartnersPage={
                pageContext?.data?.wpInvestment?.investment?.enablePartnersPage
            }
            headerData={pageContext?.headerData}
            investmentSlug={pageContext?.slug}
            pageTitle={pageTitle}
            location={location}
            displayBanner
            investmentDisabledPages={getDisabledPages(
                pageContext?.data?.wpInvestment?.investment,
            )}
        >
            <>
                <UniversalHero
                    background={partnersData?.titleBackground?.sourceUrl}
                    text={partnersData?.description}
                    title={partnersData?.title}
                />
                <PartnersSection
                    pathname={location.pathname}
                    logos={partnersData?.logos}
                />
            </>
        </Layout>
    );
};
