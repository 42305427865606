import { Link } from 'gatsby';
import { mediaQueries } from 'shared/breakpoints';
import styled from 'styled-components';
import { SectionWrapper } from 'styles/Typography.styled';

export const StyledSectionWrapper = styled(SectionWrapper)`
    display: flex;
    flex-wrap: wrap;
    gap: 120px 100px;
    padding: 160px 0px;

    ${mediaQueries.md} {
        padding: 56px 16px;
    }
    ${mediaQueries.sm} {
        justify-content: center;
        gap: 20px 100px;
    }
`;

export const Image = styled.img`
    max-width: 100%;

    ${mediaQueries.xs} {
        max-width: 220px;
    }
`;
